import gsap from 'gsap'
export default function(h){
  window.addEventListener('scroll',() => {
    const superH = h.querySelector('.superheader')
    const bound_superH = superH.getBoundingClientRect()
    const superH_height = bound_superH.height
    const scroll = window.scrollY
    const view_trigger = window.innerHeight / 4
    const speed = .6
    if (scroll > view_trigger){
      gsap.to(h,{
        duration: speed,
        y: -superH_height
      })
    } else {
      gsap.to(h,{
        duration: speed,
        y: 0
      })
    }
  })

}
