import gsap from 'gsap'

export default class Slider {
  constructor(el) {
    this.el = el
    this.active = 0
    this.list = this.el.querySelector('.slider_list')
    this.wrapper_images = this.el.querySelectorAll('.js-slider-image')
    this.image_width = this.wrapper_images[0].offsetWidth
    this.images = this.list.querySelectorAll('.slider_list_block_image')
    this.animating = false
    this.device = window.innerWidth < 768
  }
  init() {
    if (this.device) {
      return
    } else {
      this.images[this.active].classList.add('active')
      this.images.forEach((img) => {
        if (img) {
          img.addEventListener('click', this.set.bind(this))
        }
      })
    }

  }
  set(e) {
    if (this.animating) {
      return false
    }
    let target = e.target
    let index = target.getAttribute('data-index')
    if (index > this.active) {
      this.active ++
      this.animate(+1)
    } else if(index < this.active) {
      this.active --
      this.animate(-1)
    }
    this.opacity()
  }
  opacity() {
    this.images.forEach((img,i) => {
      if (this.active == i) {
        img.classList.add('active')
      } else {
        img.classList.remove('active')
      }
    })
  }
  animate(dir) {
    this.animating = true
    const move = this.image_width * dir
    gsap.to(this.list,{
      x: `-=${move}`,
      duration: .8,
      ease: 'Power1.easeOut',
      onComplete: () => {
        this.animating = false
      }
    })
  }
}
