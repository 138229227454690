import gsap from 'gsap'
export default function(cards){
  cards.forEach((card) => {
    const img = card.querySelector('img')
    card.addEventListener('mouseenter', () => {
      img.classList.add('hover')
    })
    card.addEventListener('mouseleave', () => {
      img.classList.remove('hover')     
    })
  })
}
