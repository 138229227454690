import gsap from 'gsap'

export default function(el) {
  const images = el.querySelectorAll('img')
  const counter = el.querySelector('.slideshow_index_counter > .active')
  const texts = Array.from(el.querySelectorAll('.slideshow_index_title > a'))
  const text_container = el.querySelector('.slideshow_index_title')
  const rects = el.querySelectorAll('.rect')
  let stop_animating = false
  let index = rects[0].getAttribute('rect-index')
  let j = 0
  const speed = 5000
  changeImage()
  changeCounter()
  changeText()
  activeRect()
  resetJ()

  const animation = setInterval(function() {
    j++
    index = rects[j].getAttribute('rect-index')
    changeImage()
    changeCounter()
    changeText()
    activeRect()
    resetJ()
  }, speed);

  function stopAnimation() {
    stop_animating = true
    clearInterval(animation)
  }


  rects.forEach((rect) => {
    rect.addEventListener('click', () => {
      stopAnimation()
      resetStyle()
      index = rect.getAttribute('rect-index')
      activeRect()
      changeImage()
      changeCounter()
      changeText()     
    })
  })

  function resetJ() {
    if (j == rects.length - 1) {
      j = -1
    }
  }

  function activeRect() {
    rects.forEach((rect) => {
      const rect_index = rect.getAttribute('rect-index')
      if (rect_index == index) {
        rect.classList.add('active')
      } else {
        rect.classList.remove('active')
      }
    })
  }

  function changeImage() {
    images.forEach((img) => {
      const img_index = img.getAttribute('image-index')
      if (img_index == index) {
        img.classList.add('active')
        if (stop_animating == false) {
          animateSlide(img)
        } else {
          return
        }
      } else {
        img.classList.remove('active')
      }
    })
  }

  function changeText() {
    texts.forEach((txt) => {
      const txt_index = txt.getAttribute('text-index')
      if (txt_index == index) {
        txt.classList.add('active')
      } else {
        txt.classList.remove('active')
      }
    })
  }

  function changeCounter() {
    counter.innerHTML = index
  }

  function animateSlide(el) {
    const tl = gsap.timeline()
    let duration = 2
    let delay = (speed / 1000) - (duration / 2)
    gsap.fromTo(el, {
      scale: 1.2
    }, {
      scale: 1,
      duration: duration,
      ease:"Power2.easeOut"      
    })
    if (stop_animating) {
      tl.to(el, {
        scale: 1.2,
        duration: duration
      }, delay)      
    } else {
      return
    }
  }

  function resetStyle() {
    gsap.set(text_container, {
      opacity: 1
    })
    images.forEach((img) => {
      gsap.set(img,{
        scale:1
      })
    })
  }
}
