import gsap from 'gsap'
export default function(el){
  const dropdown = el.querySelector('.language_dropdown')
  const links = Array.from(dropdown.querySelectorAll('a'))
  el.addEventListener('mouseenter',() => {
    gsap.to(dropdown,{
      css:{
        className: '+=open language_dropdown',
      },
      duration: .2,
      onComplete: () => {
        links.forEach((a) => {
          a.style.display = "block"
        })
      }
    })

  })
  el.addEventListener('mouseleave',() => {
    links.forEach((a) => {
      gsap.to(a,{
        css:{
          display: 'none'
        },
        duration: .2,
        onComplete: () => {
          dropdown.classList.remove('open')
        }
      })
    })
  })
}
