import '../../scss/application.scss'
import Form from '../components/form.js'
import Slider from '../components/slider.js'
import Zoom from '../components/zoom.js'
import Menu from '../components/menu.js'
import Slideshow from '../components/slideshow.js'
import Cards from '../components/cards.js'
import Header from '../components/header.js'
import Languages from '../components/language.js'
import MenuMobile from '../components/menu_mobile.js'
import Blog from '../components/blog.js'
import { Loader } from "@googlemaps/js-api-loader"
import {qs,qsa} from '../utils/lib'
import HomePagePopup from '../components/hp_popup'

document.addEventListener('DOMContentLoaded', () => {

    const btn = document.querySelector('.js-menu-mobile')
    if (btn) {
      const menu_m = new MenuMobile(btn)
      menu_m.init()
    }
    const language_btn = document.querySelector('.js-language')
    if (language_btn){
      Languages(language_btn)
    }
      const form = document.querySelector('.new_contact_message')
      if (form) {
        const form_app = new Form(form)
        form_app.init()    
      }
    
      const blog_btn = document.querySelector('.js-blog')
      if (blog_btn){
        Blog(blog_btn)
      }
    
    
      const header = document.querySelector('.js-header')
      if (header) {
        Header(header)
      }

      const slid_show = document.querySelector('.js-slideshow')
      if (slid_show) {
        Slideshow(slid_show)
      }
    
      const menu_btns = Array.from(document.querySelectorAll('.js-menu'))
      menu_btns.forEach((btn) => {
        btn.addEventListener('mouseenter', (e) => {
          btn.classList.add('active')
          const mn = new Menu(btn,e)
          mn.init()
        })
        btn.addEventListener('mouseleave', () => {
          btn.classList.remove('active')
          const menus = Array.from(document.querySelectorAll('.menu'))
          menus.forEach((menu) => {
            if (menu.classList.contains('open')){
              menu.classList.remove('open')
            }
          })
        })
      })
    
      const zoom_pages = Array.from(document.querySelectorAll('.js-zoom-page'))
      if (zoom_pages) {
        zoom_pages.forEach((page) => {
          const zoom = new Zoom(page)
          zoom.init()
        })
      }
    
      const sliders = Array.from(document.querySelectorAll('.js-slider'))
      if (sliders) {
        sliders.forEach((slider) => {
          const sld = new Slider(slider)
          sld.init()
        })
      }
    
      const cards = Array.from(document.querySelectorAll('.card'))
      if (cards) {
        Cards(cards)
      }
  
      // Mappa Google Maps Pagina contatti
      const position =  { lat: 44.99025977056314, lng: 9.064637724811465 }
      const url = "/images/marker.png" // url
      const map_wrapper = qs(['#map'])
      if (map_wrapper) {
        const loader = new Loader({
          apiKey: "AIzaSyBBOSxDSg-3qle4wJVBR-j14U7A5lI29Gs",
          version: "weekly"
        });
        loader.load().then(() => {
          let map = new google.maps.Map(map_wrapper, {
            center: position,
            zoom: 14,
          });
          const icon = {
            url: url,
            scaledSize: new google.maps.Size(40, 40), // scaled size
            origin: new google.maps.Point(0,0), // origin
            anchor: new google.maps.Point(20, 40  ) // anchor
        };        
          const marker = new google.maps.Marker({
            position: position,
            icon: icon,
            map: map
          });
        });
        
      }
      const hp_p = qs('.hp_popup')
      hp_p && new HomePagePopup(hp_p)


})
