import gsap from 'gsap'
export default class Menu {
    constructor(el, event) {
        this.el = el
        this.col = event.target
        this.col_attr = this.col.getAttribute('data-menu')
        this.menus = Array.from(document.querySelectorAll('.menu'))

    }
    init() {
        this.menus.forEach((menu) => {
            const menu_attr = menu.getAttribute('data-menu')
            if (this.col_attr == menu_attr) {
                menu.classList.add('open')
                this.menuAnimation(this.el,menu)
                this.closeMenu(menu)
            } else {
                menu.classList.remove('open')
            }
        })
    }
    menuAnimation(btn,menu) {
        if (menu.classList.contains('open')) {
            const menu_cols = Array.from(menu.querySelectorAll('div.menu_col'))
            menu_cols.forEach((col) => {
                col.addEventListener('mouseenter', () => {
                    menu.classList.add('open')
                    btn.classList.add('active')
                    this.cardAnimation(col,true)
                })
                col.addEventListener('mouseleave', () => {
                    btn.classList.remove('active')
                    this.cardAnimation(col,false)
                })
            })
        }
    }
    closeMenu(menu) {
        if (menu.classList.contains('open')) {
            menu.addEventListener('mouseleave', () => {
                menu.classList.remove('open')
            })
        }
    }
    cardAnimation(col,enter){
        const img = col.querySelector('img')
            if (enter) {
                img.classList.add('hover')
            } else {
                img.classList.remove('hover')    		
            }
        

    }
}