class HomePagePopup {
    constructor(el) {
        this.el = el
        this.close = this.el.querySelector('.close')
        this.init()
    }
    init() {
        this.close.addEventListener('click', this.closePopup.bind(this))
    }
    closePopup() {
        this.el.classList.add('closed')
        document.cookie = "hp_popup=closed";
    }
}
export default HomePagePopup