import {qs,qsa} from '../utils/lib'
export default class Zoom {
  constructor(el) {
    this.el = el
    this.button = this.el.querySelector('.js-zoom-button')
    this.images = qsa('.js-zoom-image', this.el)
    this.zoom_container = document.querySelector('.zoom-container')
    this.zoom_image = this.zoom_container.querySelector('img')
    this.close_btn = this.zoom_container.querySelector('.zoom-container_close')
  }
  init() {
    this.images.forEach((img) => {
      const src = img.dataset.img || img.src
      if(src) {
        img.addEventListener('click', this.zoom.bind(this, src))
      } 
      if (this.button) {
        this.button.addEventListener('click', this.zoom.bind(this, src))
      }
    })

    this.close_btn.addEventListener('click', this.close.bind(this))
  }
  zoom(src) {
    this.zoom_container.classList.add('active')
    this.zoom_image.src = src
  }
  close() {
    this.zoom_container.classList.remove('active')
    this.zoom_image.src = ""
  }
}
