import gsap from 'gsap'
import {qs,qsa} from '../utils/lib'
export default class MenuMobile{
  constructor(el){
    this.menu = qs('.menu_mobile')
    this.el = el
    this.hamburger = qs('.hamburger',this.el)
    this.hamEls = qsa('span', this.hamburger)
    this.anim_dur = .4
    this.links = qsa('a', this.menu)
    this.setLinks(0)
  }
  init(){
    this.el.addEventListener('click', this.setMenuOpen.bind(this))
    window.addEventListener('resize', this.setMenuHeight.bind(this));
  }
  setLinks(toggle){
    if(toggle == 1) {
      gsap.to(this.links,{
        opacity:1,
        y: 0,
        stagger: .1,
        delay:.4
      })
    } else {
      gsap.set(this.links,{
        opacity: 0,
        y: 16
      })
    }

  }
  setMenuOpen(){
    const html = document.querySelector('html')
    const body = document.querySelector('body')
    if (this.menu) {
      this.menu.classList.toggle('open')
      html.classList.toggle('hidden')
      body.classList.toggle('hidden')
    }
    this.hamAnimation()
  }
  hamAnimation(){
    this.setMenuHeight()
    this.setLinks(1)
    if (this.menu.classList.contains('open')){
      gsap.to(this.hamEls[0],{
        rotation: '+=45',
        transformOrigin: '0 0',
        duration: this.anim_dur
      })
      gsap.to(this.hamEls[1],{
        opacity: 0,
        duration: this.anim_dur
      })
      gsap.to(this.hamEls[2],{
        rotation: '-=45',
        transformOrigin: '0 0',
        duration: this.anim_dur
      })
    } else {
      gsap.to(this.hamEls[0],{
        rotation: '-=45',
        transformOrigin: '0 0',
        duration: this.anim_dur
      })
      gsap.to(this.hamEls[1],{
        opacity: 1,
        duration: this.anim_dur
      })
      gsap.to(this.hamEls[2],{
        rotation: '+=45',
        transformOrigin: '0 0',
        duration: this.anim_dur
      })

    }
  }

  setMenuHeight(){
    let vh = window.innerHeight * 0.01;
    this.menu.style.setProperty('--vh', `${vh}px`);
  }
}

