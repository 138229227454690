export default class Form {
    constructor(el) {
        this.el = el
        this.inputs = Array.from(this.el.querySelectorAll('input'))
        this.selects = Array.from(this.el.querySelectorAll('select'))
        this.btn = this.el.querySelector('.js_submit')
        this.popup = this.el.querySelector('.popup_form')
    }
    init() {
        this.inputs.forEach((input) => {
            input.addEventListener('focus', () => {
                this.inputAnimations(input.parentNode, true)
            })
            input.addEventListener('blur', () => {
                this.inputAnimations(input.parentNode, false)
            })
        })
        this.selects.forEach((sel) => {
            sel.addEventListener('focus', () => {
                this.selectAnimations(sel.parentNode)
            })
        })
        this.el.addEventListener('submit', (e) => {
            e.preventDefault()
            this.openPopup()
        })
        this.btn.addEventListener("click",() => {
            this.el.submit()
        })
    }
    inputAnimations(el, isActive) {
        const label = el.querySelector('label')
        if (isActive) {
            label.classList.add('active')
            el.classList.add('active')
        } else {
            el.classList.remove('active')
            if (el.value == "") {
                el.classList.remove('fill')
                label.classList.remove('active')
            } else {
                el.classList.add('fill')
            }
        }
    }
    selectAnimations(el) {
        const lb = el.querySelector('label')
        lb.classList.add('active')
    }
    openPopup(){
        this.popup.classList.add('open')
    }
}